@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    /* ... */
    font-family: 'Noto Sans JP', sans-serif;  
  }
  body {
    @apply bg-white text-black w-full h-full !scrollbar-track-transparent !scrollbar-thumb-[#6e747c] !scrollbar-none scrollbar
  }
  header {
    @apply fixed top-0 z-50 flex w-full items-center justify-between px-4 py-4 transition-all lg:px-10 lg:py-6
  }
}

@layer components {
  .headerLink {
    @apply cursor-pointer text-sm md:text-base font-thin text-black transition duration-[.4s] scale-105 list-none; 
  }
  .containerApp {
    @apply h-screen w-full bg-gray-600; 
  }
  .goldYellowText {
    @apply text-[#e09c26]
  }
  .goldYellowOffText {
    @apply text-[#b37a47]
  }
  .lightGray {
    @apply text-[#c5c5c5]
  }
  .lightYellow {
    @apply text-[#f1bd5a]
  }
  .telugu {
    font-family: 'Noto Serif Telugu', serif;
  }
  @media (max-width: 560px) {
    /* … */
    .contain-text {
      @apply !pt-0
    }
    .containContainer{
      @apply !h-[150vh]
    }
    .contain{
      @apply !min-h-[150vh]
    }
  }
  @media screen {
    @media (max-width: 560px) {
      @media (max-height: 780px) {
      /* … */
      .contain-text {
        @apply !pt-24
      }
      .containContainer{
        @apply !h-[230vh]
      }
      .contain{
        @apply !min-h-[230vh]
      }
      }
      @media (max-height: 651px) {
      .contain-text {
        @apply !pt-24
      }
      .containContainer{
        @apply !h-[260vh]
      }
      .contain{
        @apply !min-h-[260vh]
      }
      }
  }
    
  }


}
